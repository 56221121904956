.select-btn-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 12px;
  padding: 0 20px;
  margin-top: 20px;
  @media all and (max-width: 680px) {
    gap: 12px;
  }
}
.region-wrapper {
  width: 400px;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  margin: 10px 0;
  height: 500px;
  overflow: scroll;
  @media all and (max-width: 480px) {
      width: 250px;
  }
  @media all and (max-width: 650px) {
    height: 300px;
  }
}
.select-btn-wrapper {
  .select-btn {
    background-color: transparent;
    padding: 8px 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    justify-content: flex-start;
    gap: 10px;
    display: flex;
    align-items: center;
    height: 100%;
    color: #1e1e1e;
    width: 100%;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.28px;
    position: relative;
    &.sidebar {
      font-size: 12px;
      width: 85%;

      input[type="radio"] {
        width: 16px;
        height: 16px;
        margin: 4px;
        background-size: cover
      }
    }
    .select-content {
      position: absolute;
      top: 110%;
      border-radius: 8px;
      background: var(--Dark-D0, #ffffff);
      box-shadow: 0px 4px 48px 0px rgba(0, 0, 0, 0.1);
      padding: 12px 16px 16px;
      width: 148px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      height: 200px;
      overflow: scroll;
      z-index: 10;
      hr {
        color: #2426272f;
        margin: 0;
      }
      .item {
        color: #1e1e1e;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.28px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  input[type="radio"] {
    box-sizing: border-box;
    width: 22px;
    height: 22px;
    margin: 6px;
    padding: 0;
    border: 2px solid var(--checkbox-border-color);
    appearance: none;
    background-color: transparent;
    outline: none;
    transition: outline 0.1s;
    background-image: url(../../../../assets/img/checkbox.png);
  }
  input[type="radio"]:checked {
    background-size: cover;
    padding: 2px;
  }

  input[type="radio"]:not(:disabled):checked {
    border-color: var(--checkbox-checked-color);
    background-image: url(../../../../assets/img/checked.png);
  }
}
