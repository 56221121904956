#search-bar {
    border-radius: 44px;
    background: #fff;
    height: 83px;
    max-width: 896px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 6px;
    margin-bottom: 95px;
    margin-top: 20px;
    position: relative;
    @media all and (max-width: 991px) {
        flex-direction: column;
        height: auto;
        padding: 15px;
        border-radius: 10px;
        gap: 19px;
        margin-bottom: 57px;
    }

    @media all and (min-width: 991px) {
      &.sidebar {
        display: none;
      }
    }

    a {
        text-decoration: none;
    }
    .border-right {
        background-color: #e2e8f0;
        height: 35px;
        width: 1px;
        @media all and (max-width: 991px) {
            display: none;
        }
    }

    h6 {
        color: #64748b;
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0;
        @media all and (max-width: 575px) {
            font-size: 10px;
        }
    }

    h5 {
        color: #000;
        font-family: "Open Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 0;
        @media all and (max-width: 575px) {
            font-size: 14px;
        }
    }

    h3 {
        color: #1e1e1e;
        font-family: "Open Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.36px;
        margin: 0;
        @media all and (max-width: 575px) {
            font-size: 14px;
        }
    }

    .select-items-wrapper {
        padding: 20px 0 17px 0;
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        @media all and (max-width: 991px) {
            padding: 0;
            width: 100%;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
            gap: 11px;
        }
    }

    .select-item {
        display: flex;
        // align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 5px;
        @media all and (max-width: 991px) {
            border-radius: 7px;
            border: 1px solid #e2e8f0;
            padding: 13px 19px;
            gap: 3px;
            &.sidebar {
                width: 90%;
            }
        }
    }

    .search-btn {
        color: #fff;
        leading-trim: both;
        text-edge: cap;
        font-family: "Open Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border-radius: 44px;
        background: #2563eb;
        height: 100%;
        padding: 0 22px;
        border: none;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        @media all and (max-width: 991px) {
            width: 100%;
            padding: 18px 0;
            border-radius: 44px;
            font-size: 16px;
        }
    }

    .select-contents {
        border-radius: 4px 4px 10px 10px;
        border: 1px solid rgba(0, 0, 0, 0.05);

        background: #fff;

        box-shadow: 0px 4px 48px 0px rgba(0, 0, 0, 0.1);
        padding: 16px 20px;
        @media all and (max-width: 575px) {
            padding: 16px 10px;
        }
        // margin-top: 50px;
    }

    .dropdown-menu.show {
        padding: 0 !important;
        border: none !important;
        // width: fit-content !important;
    }
    .dropdown-menu {
        // width: 786px !important;
        margin-top: 20px;
        border-radius: 4px 4px 10px 10px;
        border: 1px solid rgba(0, 0, 0, 0.05);
        background: #fff;
        box-shadow: 0px 4px 48px 0px rgba(0, 0, 0, 0.1);

        @media all and (max-width: 991px) {
            width: 500px !important;
        }
        @media all and (max-width: 608px) {
            width: 100% !important;
        }
    }
    .dropdown-toggle {
        background-color: transparent !important;
        border: none !important;
        padding: 0 !important;
        margin: 0 !important;
    }
    .dropdown-toggle::after {
        display: none !important;
    }

    // holiday
    .holiday-grid-contents {
        margin-top: 14px;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 16px;

        @media all and (max-width: 991px) {
            grid-template-columns: repeat(5, 1fr);
        }
        @media all and (max-width: 768px) {
            grid-template-columns: repeat(5, 1fr);
        }
        @media all and (max-width: 525px) {
            grid-template-columns: repeat(3, 1fr);
            gap: 8px;
        }

        h2 {
            font-family: "Open Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px; /* 112.5% */
            letter-spacing: -0.32px;
            margin: 0;
            text-align: center;
            @media all and (max-width: 991px) {
                font-size: 10px;
                line-height: normal;
            }
        }
        p {
            color: #fff;
            font-family: "Open Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px; /* 114.286% */
            letter-spacing: -0.28px;
            margin: 0;
            @media all and (max-width: 991px) {
                font-size: 8px;
                line-height: normal;
            }
        }

        .text-content {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            z-index: 2;
            gap: 4px;
            top: 0;
            left: 0;
        }
        .item {
            cursor: pointer;
            position: relative;
        }
        img {
            width: 100%;
            border-radius: 14px;
            z-index: 1;
        }
    }
}
